import React, { useState } from "react";
import Seo from "../components/seo";
import "../styles/main.scss";
import ogIMage from "../images/og.png";
import { StaticImage } from "gatsby-plugin-image";
import ClinicalMindLogo from "../images/sedstart/clinical-mind.svg";
import IlifeLogo from "../images/sedstart/Ilife-1.svg";
import TuneCoreLogo from "../images/sedstart/tunecore.svg";
import NinjaSquareLogo from "../images/sedstart/Ninja-Quoter-1.svg";
import QReportLogo from "../images/sedstart/Qreport-1.svg";
// import EnphaseLogo from "../images/sedstart/enphase.svg";
// import RewaLogo from "../images/sedstart/rewa.svg";
import PriceBookLogo from "../images/sedstart/pricebook.svg";
import SedSolutionOne from "../images/sedstart/sed-solution-1.svg";
import SedSolutionTwo from "../images/sedstart/sed-solution-2.svg";
import SedSolutionThree from "../images/sedstart/sed-solution-3.svg";
import SedSolutionFour from "../images/sedstart/sed-solution-4.svg";
import SedSolutionFive from "../images/sedstart/sed-solution-5.svg";
import SedSolutionSix from "../images/sedstart/sed-solution-6.svg";
import SedSolutionSeven from "../images/sedstart/sed-solution-7.svg";
import SedSolutionEight from "../images/sedstart/sed-solution-8.svg";
import SedSolutionNine from "../images/sedstart/sed-solution-9.svg";
import SedSolutionTen from "../images/sedstart/sed-solution-10.svg";
import SedSolutionEleven from "../images/sedstart/sed-solution-11.svg";
import SedSolutionTwelve from "../images/sedstart/sed-solution-12.svg";
import HorizontalLine from "../images/sedstart/horizontal-line.svg";
import VerticalLineOne from "../images/sedstart/vertical-line-1.svg";
import VerticalLineTwo from "../images/sedstart/vertical-line-2.svg";
import WhySedStart from "../components/WhySedStart";
// import SedinLogoRound from "../images/cad-service/sedin-round.svg";
// import SedinLogoText from "../images/cad-service/sedin-text.svg";
import SedinWhiteLogo from "../images/sedstart/Sedinlogo-white.svg";
import GetQuote from "../images/sedstart/get-quote-sed.svg";
import LeftSideIcon from "../images/sedstart/left-side.svg";
import RightSideIcon from "../images/sedstart/right-side.svg";
import { Link } from "gatsby";
import SedStartExplore from "../components/SedStart-ExploreSec";
import SedStartSteps from "../components/SedstartSteps";
import SedStartFaq from "../components/SedStartFaq";
import StartNow from "../images/sedstart/start-now.svg";
import HeroSecGif from "../images/sedstart/Hero-Sedstart.gif";
import AutomationTestingfirstGif from "../images/sedstart/Automation testing.gif";
import AutomationBgImg from "../images/sedstart/automation-first.svg";
import TestAutomationGif from "../images/sedstart/Test automation.gif";
import CICDThirdGif from "../images/sedstart/ci-cd-pipeline.gif";
import CiCDBgImgSvg from "../images/sedstart/cicd-1.svg";
import { CSSTransition } from "react-transition-group";
import { Helmet } from "react-helmet";
import QAserviceForm from "../components/QA-ServiceForm";

const SedStartPage = () => {
    const [showModal, setShowModal] = useState(false);
    const handleModal = () => {
        setShowModal(true);
    };
    const sedSolutionsImage = [
        {
            id: 1,
            solutionImage: SedSolutionOne,
        },
        {
            id: 2,
            solutionImage: SedSolutionTwo,
        },
        {
            id: 3,
            solutionImage: SedSolutionThree,
        },
        {
            id: 4,
            solutionImage: SedSolutionFour,
        },
        {
            id: 5,
            solutionImage: SedSolutionFive,
        },
        {
            id: 6,
            solutionImage: SedSolutionSix,
        },
        {
            id: 7,
            solutionImage: SedSolutionSeven,
        },
        {
            id: 8,
            solutionImage: SedSolutionEight,
        },
        {
            id: 9,
            solutionImage: SedSolutionNine,
        },
        {
            id: 10,
            solutionImage: SedSolutionTen,
        },
        {
            id: 11,
            solutionImage: SedSolutionEleven,
        },
        {
            id: 12,
            solutionImage: SedSolutionTwelve,
        },
    ];
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <Seo title="Sedin - Sedstart" image={ogIMage} />

            <section className="sedstart-header">
                <div className="container">
                    <div className="site-logo">
                        <Link to="/" className="logo-inner">
                            <img
                                src={SedinWhiteLogo}
                                alt="sedin-logo"
                                loading="lazy"
                                className="sedin-logo-text"
                            />
                        </Link>
                        <div className="flex flex-ai-c contact-us-qa">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_1_2509)">
                                    <path
                                        d="M18.6797 4.6875C20.2699 5.11427 21.7198 5.95178 22.884 7.11599C24.0482 8.2802 24.8857 9.73014 25.3125 11.3203"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M17.707 8.30859C18.6634 8.56259 19.5357 9.0649 20.2354 9.76462C20.9351 10.4643 21.4374 11.3366 21.6914 12.293"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M10.8398 14.625C11.8046 16.6172 13.4155 18.224 15.4102 19.1836C15.5573 19.2533 15.72 19.2834 15.8824 19.2711C16.0447 19.2588 16.201 19.2044 16.3359 19.1133L19.2656 17.1563C19.3951 17.0685 19.5446 17.0149 19.7004 17.0006C19.8561 16.9862 20.013 17.0116 20.1563 17.0742L25.6406 19.4297C25.8281 19.5077 25.9847 19.6452 26.0863 19.8209C26.1879 19.9967 26.229 20.201 26.2031 20.4024C26.0293 21.7591 25.3671 23.006 24.3404 23.9097C23.3137 24.8135 21.9928 25.3122 20.625 25.3125C16.3981 25.3125 12.3443 23.6334 9.35549 20.6445C6.36663 17.6557 4.6875 13.6019 4.6875 9.37501C4.68781 8.00719 5.1865 6.68635 6.09026 5.65964C6.99403 4.63293 8.24093 3.97071 9.59766 3.79688C9.79903 3.77102 10.0033 3.81206 10.1791 3.9137C10.3548 4.01534 10.4923 4.17194 10.5703 4.35938L12.9258 9.85548C12.987 9.99651 13.0127 10.1504 13.0004 10.3037C12.9881 10.457 12.9384 10.6049 12.8555 10.7344L10.8984 13.7109C10.8113 13.8456 10.7602 14.0003 10.7499 14.1603C10.7397 14.3204 10.7706 14.4804 10.8398 14.625Z"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_2509">
                                        <rect width="30" height="30" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <a className="contact" href="tel: +1 415-903-3658">
                                Call Us: +1 415-903-3658
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {/* sedstart hero sec  */}
            <section className="sedstart-hero-sec">
                <div className="container">
                    <div className="sedstart-hero-sec__text">
                        <h1 className="text-h1 text-fw-medium text-clr-white sedstart-hero-title">
                            The perfect{" "}
                            <span className="why-sedstart__text-orangeclr">
                                Testing Automation Tool
                            </span>{" "}
                            for
                        </h1>
                        <h1 className="text-h1 text-fw-medium text-clr-white gap-05x sedstart-hero-title">
                            {" "}
                            <span className="why-sedstart__text-orangeclr">Testers</span> at any
                            skill level
                        </h1>
                        <p className="text-p4 text-fw-normal text-clr-white sedstart-hero-para gap-1x">
                            In A Nutshell, SedStart Is The Future Of  Automation Testing, Say
                            Goodbye To Traditional Manual Testing Woes…{" "}
                        </p>
                        <div className="flex flex-ai-c flex-jc-c sedstart-btn">
                            <button className="get-free-btn" onClick={handleModal}>
                                Schedule a Demo ﹥
                            </button>
                            {/* <button className="write-us">Write us</button> */}
                            <img src={GetQuote} alt="" className="get-quote-img" />
                        </div>
                        <div className="flex flex-ai-c flex-jc-c support-span">
                            <span className="quick">No Scripting</span>
                            <span>|</span>
                            <span className="support">All Design</span>
                            <span>|</span>
                            <span className="dedicated-team">Free POC</span>
                        </div>
                        <div className="">
                            {/* <img src={LeftSideIcon} alt="left-icon" className="left-icon" /> */}
                            {/* <img src={RightSideIcon} alt="right-icon" className="right-icon" /> */}
                            <img
                                src={HeroSecGif}
                                alt="gif"
                                className="hero-sec-gif"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </section>
            {/* sedstart client sec  */}
            <section className="sedstart-clients">
                <div className="container container--first">
                    <p className="text-p3 text-fw-medium text-clr-primary gap-2x flex flex-jc-c">
                        Trusted by the highest performance teams at
                    </p>
                    <div className="sedstart-clients-grid">
                        {/* <img
                            src={ClinicalMindLogo}
                            alt="clinical-mind-logo"
                        /> */}
                        <img src={PriceBookLogo} alt="pricebook-logo" />
                        <img src={NinjaSquareLogo} alt="NinjaSquare-logo" />
                        {/* <img src={EnphaseLogo} alt="enphase-logo" /> */}
                        <img src={IlifeLogo} alt="ilife-logo" />
                        <img src={QReportLogo} alt="rewa-logo" />
                        <img src={TuneCoreLogo} alt="tunecore-logo" />
                    </div>
                </div>
            </section>
            {/* sedstart content sec  */}
            <section className="sedstart-content">
                <div className="container container--mid-v2">
                    <div className="sedstart-content-grid">
                        <div className="sedstart-content-grid__item">
                            <div className="sedstart-content-grid__item-textcontent">
                                <h1 className="text-h1 text-clr-secondary text-fw-medium gap-1x">
                                    The Game Changer In{" "}
                                    <span className="text-clr-primary">Automation Testing</span>
                                </h1>
                                <p className="text-p1 text-clr-secondary text-fw-normal gap-05x">
                                    Experience the future of automation testing with SedStart, a
                                    cloud-based tool designed for simplicity and maximum efficiency.
                                </p>
                                <p className="text-p1 text-clr-secondary text-fw-normal gap-2x">
                                    Our user-friendly interface offers a low-code, no-code platform
                                    that is maintenance-free and the perfect tool for testers of all
                                    skill levels.
                                </p>
                                <button className="sdn-cta-btn schedule-demo" onClick={handleModal}>
                                    Schedule a demo ﹥
                                </button>
                            </div>
                            <div className="sedstart-content-grid__item-image-first">
                                <img
                                    src={AutomationTestingfirstGif}
                                    alt="gif"
                                    loading="lazy"
                                    className="gif-img"
                                />
                                <img src={AutomationBgImg} alt="" className="cicd-bg-img" />
                            </div>
                        </div>
                    </div>
                    <div className="sedstart-content-grid">
                        <div className="sedstart-content-grid__item">
                            <div className="sedstart-content-grid__item-image">
                                <img
                                    src={TestAutomationGif}
                                    alt="gif"
                                    loading="lazy"
                                    className="common-gif"
                                />
                            </div>
                            <div className="sedstart-content-grid__item-textcontent">
                                <div className="sedstart-content-grid__item-textcontent-rightcontent">
                                    <h2 className="text-h1 text-clr-secondary text-fw-medium gap-1x">
                                        Accelerate your{" "}
                                        <span className="text-clr-primary">Test Automation</span>{" "}
                                        with Intuitive Block based Scripting
                                    </h2>
                                    <p className="text-p1 text-clr-secondary text-fw-normal gap-05x">
                                        Experience all the design benefits of traditional scripted
                                        automation with SedStart. Our UI automation, reusability,
                                        parameterization and scriptless automation features let you
                                        say goodbye to coding & hello to effortless testing.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sedstart-content-grid">
                        <div className="sedstart-content-grid__item">
                            <div className="sedstart-content-grid__item-textcontent">
                                <div className="sedstart-content-grid__item-textcontent-rightText">
                                    <p className="text-p1 text-clr-secondary text-fw-normal gap-05x">
                                        Unleash the power of a simplified and accelerated testing
                                        platform. SedStart is designed for seamless integration, to
                                        support web testing and effortlessly connects with CI/CD
                                        pipelines, bug reporting systems, and collaboration tools.
                                    </p>
                                </div>
                            </div>
                            <div className="sedstart-content-grid__item-image-third">
                                <img
                                    src={CICDThirdGif}
                                    alt="gif"
                                    loading="lazy"
                                    className="gif-img"
                                />
                                <img src={CiCDBgImgSvg} alt="" className="cicd-bg-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* how does it work sec  */}
            <section className="how-does-work">
                <div className="container container--mid-v2">
                    <div className="sedstart-explore__text gap-4x">
                        <h1 className="text-h1 text-fw-medium text-clr-black gap-05x">
                            How Does It Work?
                        </h1>
                    </div>
                    <div className="">
                        <SedStartSteps />
                    </div>
                </div>
            </section>
            {/* sedstat hassle-free sec */}
            <section className="sedstart-solutions">
                <div className="container container--mid-v2">
                    <div className="sedstart-solutions__text gap-4x">
                        {/* <p className="text-p3 text-clr-primary text-fw-normal">
                            Lorem ipsum dolor sit amet consectetur. Cras mollis faucibus
                        </p> */}
                        <div className="text-h1 text-fw-medium text-clr-primary">
                            SedStart offers end to end solution for
                        </div>
                        <div className="text-h1 text-fw-medium text-clr-primary">
                            Hassle-Free Testing
                        </div>
                    </div>
                    <div className="sedstart-solutions__grid">
                        {sedSolutionsImage?.map((sedsolution, i) => (
                            <div className="sedstart-solutions__grid-item" key={i}>
                                <img
                                    src={sedsolution.solutionImage}
                                    alt="sedsolution"
                                    className="sedsolution-img"
                                    loading="lazy"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/* why sedstart sec  */}
            <section className="why-sedstart">
                <div className="container container--mid-v2">
                    <div className="why-sedstart__text gap-4x">
                        <p className="text-p3 text-clr-white text-fw-normal gap-05x">
                            Why SedStart?
                        </p>
                        <div className="text-h1 text-fw-medium text-clr-white">
                            Escape  from all the Limitations & Traditional constraint in{" "}
                            <span className="why-sedstart__text-orangeclr">
                                Testing & Flee Towards Unlimited Possibilities
                            </span>
                        </div>
                    </div>
                    <div className="gap-4x sedstart-box">
                        <WhySedStart />
                        <div className="horizontal-line">
                            <img src={HorizontalLine} alt="horizontal-line" />
                        </div>
                        <div className="vertical-line-one">
                            <img src={VerticalLineOne} alt="vertical-line" />
                        </div>
                        <div className="vertical-line-two">
                            <img src={VerticalLineTwo} alt="vertical-line" />
                        </div>
                    </div>
                    <div class="dashed-line-horizontal">
                        <button className="dashed-button" onClick={handleModal}>
                            Start a Three Week Risk-free Trial
                        </button>
                    </div>
                </div>
            </section>
            {/* sedstart explore sec  */}
            <section className="sedstart-explore">
                <div className="container container--mid-v2">
                    <div className="sedstart-explore__text gap-4x">
                        <h1 className="text-h1 text-fw-medium text-clr-secondary gap-05x">
                            Explore What <span className="text-clr-primary">You Can Test?</span>
                        </h1>
                        {/* <p className="text-p3 text-clr-secondary text-fw-normal">
                            Lorem ipsum dolor sit amet consectetur, Lorem ipsum dolor sit amet
                            consectetur, Lorem ipsum dolor sit amet consectetur
                        </p> */}
                    </div>
                    <div className="">
                        <SedStartExplore />
                    </div>
                </div>
            </section>

            {/* list of tools  */}
            <section className="sedstart-automation">
                <div className="container container--mid-v2">
                    <div className="why-sedstart__text gap-4x">
                        {/* <p className="text-p3 text-clr-white text-fw-normal gap-05x">
                            Why SedStart?
                        </p> */}
                        <div className="text-h1 text-fw-medium text-clr-white">
                            Boost Your Efficiency with Best Test
                        </div>
                        <div className="text-h1 text-fw-medium">
                            <span className="why-sedstart__text-orangeclr">Automation Tools</span>
                        </div>
                    </div>
                    <div className="sedstart-automation-grid">
                        <div className="sedstart-automation-grid__item">
                            <div className="sedstart-automation-grid__item-title manual-title">
                                Manual + Scripted Automation
                            </div>
                            <div className="sedstart-automation-grid__item-list">
                                <ul>
                                    <li className="list-item-left">
                                        Expensive Automation Engineers are required to create and
                                        maintain automation
                                    </li>
                                    <li className="list-item-left">
                                        Lot of Upfront investment required in design
                                    </li>
                                    <li className="list-item-left">
                                        Time to automate first test is high
                                    </li>
                                    <li className="list-item-left">
                                        Takes lot of time to automate complex as well as simple
                                        scenarios
                                    </li>
                                    <li className="list-item-left">
                                        Requires scripting and coding knowledge
                                    </li>
                                    <li className="list-item-left">
                                        Harder to target test coverage due to high requirements on
                                        time and skills
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="sedstart-automation-grid__item">
                            <div className="sedstart-automation-grid__item-title">Sedstart</div>
                            <div className="sedstart-automation-grid__item-list">
                                <ul>
                                    <li className="list-item-right">
                                        Any level of QAs can create and maintain automation
                                    </li>
                                    <li className="list-item-right">
                                        No Upfront investment required in design
                                    </li>
                                    <li className="list-item-right">
                                        First test can be automated on day 1
                                    </li>
                                    <li className="list-item-right">
                                        All automation is done using UI hence takes really less time
                                    </li>
                                    <li className="list-item-right">
                                        No scripting and coding knowledge is required
                                    </li>
                                    <li className="list-item-right">
                                        Can target 90% of test coverage quicker
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="comparison-table">
                                <div className="table-header">
                                    <div className="header-item">Manual + Scripted Automation</div>
                                    <div className="header-item">Sedstart</div>
                                </div>
                                <div className="table-body">
                                    <div className="table-row">
                                        <div className="cell cell-left">
                                            Expensive Automation Engineers are required to create
                                            and maintain automation
                                        </div>
                                        <div className="cell cell-right">
                                            ✅ Any level of QAs can create and maintain automation
                                        </div>
                                    </div>
                                    <div className="table-row">
                                        <div className="cell">
                                            ❌ Lot of Upfront investment required in design
                                        </div>
                                        <div className="cell">
                                            ✅ No Upfront investment required in design
                                        </div>
                                    </div>
                                    <div className="table-row">
                                        <div className="cell">
                                            ❌ Time to automate first test is high
                                        </div>
                                        <div className="cell">
                                            ✅ First test can be automated on day 1
                                        </div>
                                    </div>
                                    <div className="table-row">
                                        <div className="cell">
                                            ❌ Takes lot of time to automate complex as well as
                                            simple scenarios
                                        </div>
                                        <div className="cell">
                                            ✅ All automation is done using UI hence takes really
                                            less time
                                        </div>
                                    </div>
                                    <div className="table-row">
                                        <div className="cell">
                                            ❌ Requires scripting and coding knowledge
                                        </div>
                                        <div className="cell">
                                            ✅ No scripting and coding knowledge is required
                                        </div>
                                    </div>
                                    <div className="table-row">
                                        <div className="cell">
                                            ❌ Harder to target test coverage due to high
                                            requirements on time and skills
                                        </div>
                                        <div className="cell">
                                            ✅ Can target 90% of test coverage quicker
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                </div>
            </section>
            {/* faq sec  */}
            <section className="sedstart-faq">
                <div className="container container--last">
                    <div className="sedstart-faq__text gap-4x">
                        <div className="text-p2 text-clr-secondary gap-05x">FAQ’s</div>
                        <div className="text-h1 text-clr-primary gap-05x">
                            Your questions answered
                        </div>
                    </div>
                    <SedStartFaq />
                </div>
            </section>
            <CSSTransition in={showModal} timeout={300} classNames="fade" unmountOnExit>
                <QAserviceForm showModal={showModal} setShowModal={setShowModal} footer sedStart />
            </CSSTransition>
            {/* footer sec  */}
            <section className="sedstart-footer">
                <div className="container">
                    <div className="sedstart-footer__text">
                        <div className="text-fw-medium sedstart-minititle">
                            So Why Wait? Jump In👇🏻 & Discover More 💡
                        </div>
                        <div className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            TEST SMARTER, NOT HARDER
                        </div>
                        <div className="sedstart-footer__text-cta">
                            <button className="cta-btn" onClick={handleModal}>
                                Request a Call
                            </button>
                        </div>
                        <div className="start-now">
                            <img src={StartNow} alt="" className="start" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="sedstart-footer-b">
                <div className="container">
                    <div className="footer-wrapper">
                        <p className="text-p4">© 2024 Sedin Technologies Inc.</p>
                        <a className="footer-link" href="/terms-and-conditions">
                            Terms and Conditions
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SedStartPage;
