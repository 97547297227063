import React, { useEffect, useState } from "react";
import ExploreOne from "../images/sedstart/Web.gif";
import ExploreTwo from "../images/sedstart/API-latest.gif";
import ExploreThree from "../images/sedstart/Email-latest.gif";
import ExploreFour from "../images/sedstart/2FA-latest.gif";

const explorePoints = [
    {
        id: 1,
        title: "01. Web",
        description:
            "Effortlessly handle cross-browser and cross-platform scenarios within a single test. Utilize our test platform for rapid test creation.",
        bgColor: "#FFF7EA",
        image: ExploreOne,
    },
    {
        id: 2,
        title: "02. API",
        description:
            "Easily invoke APIs, retrieve values, validate return codes, and store results as saved values for comprehensive testing.",
        bgColor: "#B6F0DD",
        image: ExploreTwo,
    },
    {
        id: 3,
        title: "03. Email",
        description:
            "Seamlessly check, receive, and render emails within our platform, ensuring complete validation and accuracy of your email functionalities.",
        bgColor: "#80B8FF",
        image: ExploreThree,
    },
    {
        id: 4,
        title: "04. 2FA",
        description:
            "Secure your applications by covering two-factor authentication (2FA) logins and validating with Time-based One-Time Passwords (TOTP) generation.",
        bgColor: "#7F96FF",
        image: ExploreFour,
    },
];
const SedStartExplore = () => {
    const [activeExplorePoint, setActiveExplorePoint] = useState(0);
    const [transitionDirection, setTransitionDirection] = useState("down");

    useEffect(() => {
        setTransitionDirection(activeExplorePoint > 0 ? "down" : "up");
    }, [activeExplorePoint]);

    const { bgColor, image } = explorePoints[activeExplorePoint];

    return (
        <div className="sedstart-explore-grid">
            <div className="sedstart-explore-grid__list">
                {explorePoints.map((item, index) => (
                    <div
                        key={item.id}
                        className={`list-item ${index === activeExplorePoint ? "active" : ""}`}
                        onClick={() => setActiveExplorePoint(index)}
                    >
                        <h1 className="list-title">{item.title}</h1>
                        {index === activeExplorePoint && (
                            <p className="list-description">{item.description}</p>
                        )}
                    </div>
                ))}
            </div>
            <div className={`content ${transitionDirection}`} style={{ backgroundColor: bgColor }}>
                <img
                    className="image"
                    src={image}
                    alt={explorePoints[activeExplorePoint].title}
                    loading="lazy"
                />
            </div>
        </div>
    );
};

export default SedStartExplore;
